<template>
  <div class="p-b-10">
      <component  :is="bottomListComponent"></component>
  </div>
</template>

<script>
export default {
  components: {
    maintenanceList: () => import('../common/maintenanceList')
  },
  data () {
    return {
      tagType: 1,
      bottomListComponent: 'maintenanceList'
    }
  }
}
</script>
